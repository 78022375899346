import React from 'react';
import './HomepageV2.css';

const HomepageV2 = () => {
  return (
    <div className="fullscreen-container">
      <img
        src="/background.png"
        alt="background"
        className="fullscreen-background"
      />
      <div className="centered-message">
        This is a private page.<br></br><br></br>Please contact me directly and I'll be happy to provide read access.
      </div>
    </div>
  );
};

export default HomepageV2;